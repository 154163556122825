import { Campminder } from "./integrate/Campminder";
import axios from "axios";
import { Sharpspring } from "./integrate/Sharpspring";
import { Google } from "./integrate/Google";

function App() {
  let email = new URLSearchParams(window.location.search).get("email");
  let userId = new URLSearchParams(window.location.search).get("userId");
  const appId = new URLSearchParams(window.location.search).get("appId");
  let appSlug = new URLSearchParams(window.location.search).get("app");
  let accountId = new URLSearchParams(window.location.search).get("accountId");
  const token = new URLSearchParams(window.location.search).get("token");
  const state = new URLSearchParams(window.location.search).get("state");

  if (state) {
    const {
      app: _app,
      accountId: _accountId,
      email: _email,
      userId: _userId,
    } = JSON.parse(state);
    if (_app) {
      appSlug = _app;
    }
    if (_accountId) {
      accountId = _accountId;
    }
    if (_email) {
      email = _email;
    }
    if (_userId) {
      userId = _userId;
    }
  }

  const integrateMyApp = async (credentials: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/app/integrated-apps`,
      {
        appId,
        credentials,
        status: "active",
      },
      {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const integrateClientApp = async (credentials: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/public/workflow-clients`,
      {
        email: email ?? "",
        userId: userId ?? "",
        appSlug,
        appCredentials: credentials,
      },
      {
        headers: {
          accountid: accountId,
        },
      }
    );
  };

  const getRespectiveApp = () => {
    switch (appSlug) {
      case "campminder":
        return (
          <Campminder
            integrateMyApp={integrateMyApp}
            integrateClientApp={integrateClientApp}
            token={token}
          />
        );
      case "sharpspring":
        return (
          <Sharpspring
            integrateMyApp={integrateMyApp}
            integrateClientApp={integrateClientApp}
            token={token}
          />
        );
      case "gmail":
      case "google_sheets":
      case "google_drive":
      case "google_forms":
      case "google_calendar":
        return (
          <Google
            accountId={accountId}
            appSlug={appSlug}
            email={email}
            userId={userId}
          />
        );
      default:
        return <></>;
    }
  };

  return getRespectiveApp();
}

export default App;
