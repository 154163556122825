import { useState } from "react";
import sharpspringLogo from "../assets/icons/sharpspringIcon.png";
import { AxiosResponse } from "axios";

type Props = {
  integrateMyApp: (credentials: any) => Promise<AxiosResponse<any, any>>;
  integrateClientApp: (credentials: any) => Promise<AxiosResponse<any, any>>;
  token?: string | null;
};

const Sharpspring = ({ integrateMyApp, integrateClientApp, token }: Props) => {
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const integrateApp = async () => {
    if (!apiSecret || !apiKey) {
      return setErrMsg("Fields cannot be empty");
    }
    setLoading(true);
    const credentials = {
      apiKey,
      apiSecret,
    };
    try {
      token
        ? await integrateMyApp(credentials)
        : await integrateClientApp(credentials);
      window.close();
    } catch (err) {
      setLoading(false);
      return setErrMsg("unable to integrate SharpSpring");
    }
    setLoading(false);
  };

  if (isLoading) {
    return (
      <div className="sharpspringLoginPage">
        <div className="container">
          <div className="loader">
            <div className="spinner" />
            <div className="text">Loading, please wait</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="sharpspringLoginPage">
      <div className="container">
        <div className="mainArea">
          <div className="logoArea">
            <img className="logo" src={sharpspringLogo} alt="Sharpspring" />
          </div>
          <div className="horizontalLine" />
          <p className="welcomeText">Welcome Back!</p>
          <div className="inputArea">
            <input
              type="text"
              id="username"
              placeholder="Username"
              autoFocus
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
          <div className="inputArea">
            <input
              type="text"
              id="password"
              placeholder="Password"
              value={apiSecret}
              onChange={(e) => setApiSecret(e.target.value)}
            />
          </div>
          <button
            className="submit"
            type="submit"
            onClick={() => integrateApp()}
          >
            Log In
          </button>
          <p className="errorMessage">{errMsg}</p>
        </div>
      </div>
    </div>
  );
};

export { Sharpspring };
