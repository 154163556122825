import { useState } from "react";
import campminderLogo from "../assets/icons/campminderIcon.png";
import campminderAdminLogin from "../assets/icons/campminderAdminLogin.png";
import { AxiosResponse } from "axios";

type Props = {
  integrateMyApp: (credentials: any) => Promise<AxiosResponse<any, any>>;
  integrateClientApp: (credentials: any) => Promise<AxiosResponse<any, any>>;
  token?: string | null;
};

const Campminder = ({ integrateMyApp, integrateClientApp, token }: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const integrateApp = async () => {
    if (username.length === 0 || password.length === 0) {
      return setErrMsg("Fields cannot be empty");
    }
    setLoading(true);
    const credentials = {
      username,
      password,
    };
    try {
      token
        ? await integrateMyApp(credentials)
        : await integrateClientApp(credentials);
      window.close();
    } catch (err) {
      setLoading(false);
      return setErrMsg("unable to integrate SharpSpring");
    }
  };

  if (isLoading) {
    return (
      <div className="campminderLoginPage">
        <div className="container">
          <div className="loader">
            <div className="spinner" />
            <div className="text">Loading, please wait</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="campminderLoginPage">
      <div className="container">
        <div className="mainArea">
          <div className="logoArea">
            <img className="logo" src={campminderLogo} alt="Campminder" />
          </div>
          <div className="verticalLine" />
          <div className="columnContainer">
            <img className="adminIcon" src={campminderAdminLogin} alt="" />
            <div className="inputArea">
              <label htmlFor="username">username</label>
              <input
                type="text"
                id="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="inputArea">
              <label htmlFor="password">password</label>
              <input
                type="text"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="submit"
              type="submit"
              onClick={() => integrateApp()}
            >
              Log In
            </button>
            <p className="errorMessage">{errMsg}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Campminder };
