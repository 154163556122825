/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";

export const Google = ({
  accountId,
  appSlug,
  email,
  userId,
}: {
  accountId?: string | null;
  appSlug?: string | null;
  email?: string | null;
  userId?: string | null;
}) => {
  const code = new URLSearchParams(window.location.search).get("code");
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const integrateGoogle = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/public/workflow-clients/google`,
        {
          code,
          appSlug,
          email: email ?? undefined,
          userId: userId ?? undefined,
        },
        {
          headers: {
            accountid: accountId,
          },
        }
      );
      window.close();
    } catch (err) {
      setLoading(false);
      setErrMsg("unable to create google integration");
    }
  };

  useEffect(() => {
    if (code) {
      integrateGoogle();
    }
  }, [code]);

  if (isLoading) {
    return (
      <div className="campminderLoginPage">
        <div className="container">
          <div className="loader">
            <div className="spinner" />
            <div className="text">Loading, please wait</div>
          </div>
        </div>
      </div>
    );
  }

  return <p className="err">{errMsg}</p>;
};
